import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { ContainerEvents } from '../pages/doc-upload';
import { S3 } from 'aws-sdk';
import { S3Factory } from '../../utils';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { User } from '../model/types';
import { AnalysisDetails, Analyte, FileObject } from '../model/analysis-details.model';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthNewService } from './auth-new.service';
import Amplify, { Auth, Storage } from 'aws-amplify';
import { delay, mergeMap, retryWhen, take } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class UploadService {
    disabledValue = new BehaviorSubject<boolean>(true);

    isSubmit: boolean;
    // Observable string sources
    private uploadContainerEventSource = new Subject<ContainerEvents>();
    private fileUploadEventSource = new Subject<FileObject>();
    URL: string = environment.serverUrl + '/analysis-data';
    BUCKET: string = environment.bucket;
    UploadFileURL: string = environment.serverUrl + '/upload-data';

    // Observable string streams
    uploadContrainerEvent$ = this.uploadContainerEventSource.asObservable();
    fileUploadEvent$ = this.fileUploadEventSource.asObservable();
    private signedInUser: User;
    private region: string;
    private analysis: Analyte;
    fileCount = 0;
    progressCount = 0;
    submitDisable: boolean
    userAttributes;
    uploadFileStatus = new Subject()

    constructor(private http: HttpClient,
        private newAuthService: AuthNewService,
        private storageservice: StorageService,
        private router: Router) {
        this.region = environment.defaultRegion || 'us-east-2';
        Amplify.configure({
            Auth: environment.cognito,
            Storage: environment.Storage
        });
        this.submitDisable = Boolean(this.storageservice.set('submit', 'true'));
        this.newAuthService.getCurrentUser();
        // this.authService.getCurrentUser((err, user: User) => {
        this.newAuthService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {

            this.signedInUser = user;
            this.userAttributes = user.attributes["custom:access"].toLowerCase();
            if (!this.signedInUser) {
                // this.authService.redirectToSignin(this.router.routerState.snapshot.root.queryParams);
                this.router.navigate(['/extra-layout/signin']);
                return;
            }


        });
    }

    setSignedInUser(user: User) {
        this.signedInUser = user;
    }

    setAnalysisData(enterData: Analyte) {
        this.analysis = enterData;
        return false;
    }

    // Upload status updates
    publishUploadContainerEvent(event: ContainerEvents) {
        this.uploadContainerEventSource.next(event);
    }

    publishFileUploadEvent(file: FileObject) {
        this.fileUploadEventSource.next(file);
    }

    setRegion(region: string) {
        this.region = region;
    }

    private preparePutObjectRequest(file: File, region: string): S3.Types.PutObjectRequest {
        // const now = new Date();

        const obj = {
            Key: [this.analysis.tempPath,
            file.name].join('/'),
            Bucket: this.BUCKET,
            Body: file,
            ContentType: file.type
        };
        return obj;
    }

    upload(file: File, progressCallback: (error: Error, progress: number, speed: number) => void, region?: string) {


        region = region || this.region;
        const s3Upload = S3Factory.getS3(region).upload(this.preparePutObjectRequest(file, region));
        s3Upload.on('httpUploadProgress', this.handleS3UploadProgress(progressCallback));
        s3Upload.send(this.handleS3UploadComplete(progressCallback));

        this.fileCount++;
        return s3Upload;
    }

    uploadFile(file, file_path: string) {
     
        const httpOptions = {
            headers: new HttpHeaders({
                'file-name': file_path + '/' + file.name,

            })
        };
        this.uploadFileStatus.next("Uploading")
        return this.http.post<any>(this.UploadFileURL, file.files, httpOptions)
    }

    SuccessUpload() {
        this.uploadFileStatus.next("Uploaded")
    }

    FailedUploaded() {
        this.uploadFileStatus.next("Failed")
    }

    private handleS3UploadProgress
        (progressCallback: (error: Error, progress: number, speed: number) => void) {
        let uploadStartTime = new Date().getTime();
        let uploadedBytes = 0;
        return (progressEvent: S3.ManagedUpload.Progress) => {
            const currentTime = new Date().getTime();
            const timeElapsedInSeconds = (currentTime - uploadStartTime) / 1000;
            if (timeElapsedInSeconds > 0) {
                const speed = (progressEvent.loaded - uploadedBytes) / timeElapsedInSeconds;
                const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

                if (progress === 100) {
                    this.progressCount++;

                    if (this.progressCount === this.fileCount) {
                        this.disabledValue.next(false);
                    }
                }
                progressCallback(undefined, progress, speed);
                uploadStartTime = currentTime;
                uploadedBytes = progressEvent.loaded;
            }
        };
    }

    private handleS3UploadComplete(
        progressCallback: (error: Error, progress: number, speed: number) => void) {
        return (error: Error, data: S3.ManagedUpload.SendData) => {
            if (error) {
                progressCallback(error, undefined, undefined);
            } else {
                progressCallback(error, 100, undefined);
            }
        };
    }

    cancel(s3Upload: S3.ManagedUpload) {
        s3Upload.abort();
    }

    // Added to check Commit
    submitForAnalysis(analysisDetailObj: AnalysisDetails) {
        this.disabledValue.next(true);
        this.userAttributes = this.userAttributes
        if (this.userAttributes === 'su' || this.userAttributes === 'ga' || this.userAttributes === 'pi') {
            analysisDetailObj.analysisStatus = 'NewAnalysis';

            try {
                return this.http.post(this.URL, analysisDetailObj)
                    .pipe(
                        catchError(this.handleError)
                    );
            } catch (error) {
                return throwError(this.handleError(error));
            }

        }
        else {
            console.log("Unauthorized User");

        }
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        let errorMessage = 'An unknown error occurred!';
        if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            errorMessage = `Client-side error: ${error.error.message}`;
        } else {
            // Backend error
            errorMessage = `Server-side error: ${error.status} - ${error.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

}
