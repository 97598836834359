import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Rx';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, DBService, DownLoadService } from '../../service';
import { Result, Table, outputGraph } from '../../model/small-molecule.model';
import { AnalysisDetails, NewTable, SearchResult } from '../../model/analysis-details.model';
// import { NgxXml2jsonService } from 'ngx-xml2json';
import { SharedService } from '../../layouts/shared-service';
import { User } from '../../model/types';
import { DropDownService } from '../../service/drop-down.service';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
// import { GraphDialogComponent } from './graph-dialog/graph-dialog.component';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';
import { interval } from 'rxjs/internal/observable/interval';
import { debug, error } from 'console';
import { bool } from 'aws-sdk/clients/signer';
import { ColumnDialogComponent } from './column-dialog/column-dialog.component';
import { AuthNewService } from '../../service/auth-new.service';
import { StorageService } from '../../service/storage.service';
import * as Plotly from 'plotly.js-dist';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';



@Component({
    selector: 'app-small-molecule',
    templateUrl: './small-molecule.component.html',
    styleUrls: ['./small-molecule.component.scss']
})
export class SmallMoleculeComponent implements OnInit, OnDestroy {

    pageTitle = 'Analyze Report/Data';
    signedInUser: any;
    analysisId = '';
    searchResult: SearchResult = <SearchResult>{};
    // analysisResponseNew: SearchResult = <SearchResult>{};
    selectedTables: string;
    analysisDetails: AnalysisDetails;
    public tables: Table[] = [];
    tablesKey: any = [];
    tablesValue: any = [];
    stattablesKey: any = [];
    stattablesValue: any = [];
    tablesList: NewTable[];
    tablesNotFound: any = [];
    tableData: any;
    tableAnalysisType: string;
    routersub: ISubscription;
    callerSubscription: Subscription;
    tableSelected = false;
    tableProcessed = false;
    reviewTableSelection = false;
    public results: Result[];
    tempData: any;
    title = 'Loading';
    mysubscription: any;
    visibleData = 0;
    public showcharts = false;
    item;
    output_graph: outputGraph[];
    graphs: any[] = [];
    // public Histograms = [];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    // @ViewChild('textData') data: ElementRef;

    step = 0;
    updateOptions: Object;
    data: any;
    options: {};
    navigationSubscription: Subscription;
    usr: any;
    formType: any;
    currentUser: User;
    illegalUser: boolean = false;
    sData: SearchResult;
    checked: boolean = false;
    disabled: boolean = false;
    displayTable: boolean = false;
    isFetching: boolean = true;
    displayPDFData: boolean = true;
    displayExPanels: boolean = true;
    searchResultData: boolean = true;
    errortable: any;
    extractrunid = [];
    columnArray = [];
    dataArray = [];
    jsonDataArray = [];
    displayObservation: boolean = false;
    keysWithTrueValue = [];
    tableId = [];
    identifiedColumnsArray: any[] = [];
    signedInUserAccess;
    private singleSearchData: Subscription;
    private currentLoginUser: Subscription;
    @ViewChild('linearPlot', { static: false }) linearPlot!: ElementRef;
    selectedCheckboxes: boolean[] = [];
    selectedRows: number[] = [];
    initialSampleNames = [];
    commentText: { plate: string, comment: string }[] = [];
    disableSubmit: boolean = true
    executionId: string;
    submitResultLoader: boolean = false
    submitEnabled: boolean = true;
    removedsampleid: { plate: string, sampleName: string[], comment: string, flag: boolean[] }[] = [];
    tableGraphData;
    AnalysisDataURL: string = environment.serverUrl + '/analysis-data';
    getAnalysisDataError:string='';

    constructor(private _sharedService: SharedService,
        private http: HttpClient,
        private activatedroute: ActivatedRoute,
        private AuthService: AuthNewService,
        private elRef: ElementRef,
        private router: Router,
        private dbService: DBService,
        private dropDownService: DropDownService,
        private downloadService: DownLoadService,
        public dialog: MatDialog,
        // private ngxXml2jsonService: NgxXml2jsonService,
        private ref: ChangeDetectorRef,
        private loader: AppLoaderService,
        private storageService: StorageService
    ) {
        this._sharedService.emitChange(this.pageTitle);
        // if(this.tables.length==0){
        //     this.openDialog()
        // }


        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // console.log(e)
            if (e instanceof NavigationEnd) {
                this.initialiseInvites();
            }
        });




    }


    initialiseInvites() {
        this.AuthService.getCurrentUser((err, user: User) => {
            this.currentUser = user;
            // console.log(user)
            this.activatedroute.paramMap.subscribe((params) => {

                if (params.get('user') !== this.currentUser.username) {
                    return
                } else {
                    this.usr = params.get('user');
                    this.analysisId = params.get('id');
                }

            });
        });

    }

    ngOnInit() {
        this.pollForResult();

        this.singleSearchData = this.dbService.singleSearch.subscribe(
            (data: SearchResult) => {
                if (Object.keys(data).length === 0) {
                    this.searchResultData = false;

                } else {
                    this.isFetching = false;

                }

                data && this.populateResultData(data);

            },
            (error) => {
                console.log( error);
        }
        );
        this.storageService.remove('xyz');

    }


    pollForResult() {
        this.AuthService.getCurrentUser();
        this.currentLoginUser = this.AuthService.getCurrentLoginUser.subscribe((user: any) => {
            this.signedInUser = user;
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                this.illegalUser = true;
                return;
            } else {
                this.signedInUserAccess = this.signedInUser.attributes["custom:access"].toLowerCase();
                this.activatedroute.paramMap.subscribe((params) => {
                    if (this.signedInUserAccess == 'su' || this.signedInUserAccess == 'ga' || this.signedInUserAccess == 'qa' || this.signedInUserAccess == 'pi') {
                        this.usr = params.get('user');
                        this.analysisId = params.get('id');
                        this.dbService.getAnalysisDetails(this.usr, this.analysisId);
                        this.illegalUser = false;
                    }

                });
            }

        })
        this.ref.detectChanges();
    }

    shouldDisableRow(index: number): boolean {
        // Replace this with your actual condition to disable the row
        return true; // Example: disable every even row
    }

    toggleSelection(event: any, dataObj, index: number, idx) {

        const sampleName = dataObj.sample[index];
        const sampleindex = this.removedsampleid[idx].sampleName.indexOf(sampleName);
        this.removedsampleid[idx].sampleName.splice(sampleindex);
        this.selectedCheckboxes[index] = event.target.checked;
        dataObj.flag[index] = !dataObj.flag[index];
        this.removedsampleid[idx].flag[index] = !this.removedsampleid[idx].flag[index]
        this.GraphDisplay(dataObj, idx);

    }


    GraphDisplay(dataObj, index) {

        this.updateSubmitEnabled();
        const selectedIndices = dataObj?.flag.map((flag, index) => flag && !this.selectedRows.includes(index));
        const selectedXValues = selectedIndices.map((flag, index) => flag ? dataObj.nominal[index] : null).filter(val => val !== null);
        const selectedYValues = selectedIndices.map((flag, index) => flag ? dataObj.rlu_mean[index] : null).filter(val => val !== null);
        let selectedSampleNames = selectedIndices.map((flag, index) => flag ? dataObj.sample[index] : null).filter(val => val !== null);
        const PLparameters = dataObj.params_4pl;

        const anchor_nominal = dataObj.anchor_nominal;
        const anchor_rlu = dataObj.anchor_rlu;
        let filteredrluValue = dataObj.rlu;
        let nominaValue = dataObj.nominal;
        nominaValue=nominaValue.concat(anchor_nominal)
        filteredrluValue= filteredrluValue.concat(anchor_rlu);

        nominaValue.sort((a, b) => b - a);
        filteredrluValue.sort((a, b) => b - a);
       

        const filterednominal = dataObj?.nominal.filter((_, index) => !selectedIndices[index]);
        const filteredrluMean = dataObj?.rlu_mean.filter((_, index) => !selectedIndices[index]);
        this.initialSampleNames = dataObj.sample;
        this.initialSampleNames = this.initialSampleNames?.filter(res => !selectedSampleNames.includes(res));

        if (selectedSampleNames.length > 0) {
            this.removedsampleid[index]?.sampleName.push(selectedSampleNames);
            this.searchResult.analysisData.removed_sample_id = this.removedsampleid

        }

        const graphdata = [
            {
                x: nominaValue,
                y: filteredrluValue,
                name: 'RLU',
                mode: 'lines',
                type: 'scatter',
                line: { shape: 'spline', dash: 'dot', smoothing: 1.3 },
                marker: {
                    color: '#20B2AA'
                }
            },
            {
                x: dataObj.anchor_nominal,
                y: dataObj.anchor_rlu_mean,
                text: dataObj.anchor_sample,
                name: 'Anchor',
                mode: 'markers',
                type: 'scatter',
                line: { shape: 'spline', dash: 'dot', smoothing: 1.3 },
                marker: {
                    color: '#008000',
                    size: 10,
                    symbol: 'star',
                }
            },

            {
                x: filterednominal,
                y: filteredrluMean,
                text: this.initialSampleNames,
                name: 'Sample',
                mode: 'markers',
                type: 'scatter',
                line: { shape: 'spline', dash: 'dot', smoothing: 1.3 },
                marker: {
                    color: '#FF7600',
                    size: 10,
                    symbol: 'circle',
                }
            },
            {
                x: selectedXValues,
                y: selectedYValues,
                mode: 'markers',
                type: 'scatter',
                name: 'Deselected Sample',
                text: selectedSampleNames,
                textposition: 'top center',
                marker: {
                    color: '#0000FF',
                    size: 10,
                    symbol: 'diamond',
                },
                line: { shape: 'spline', smoothing: 1.3 },
            }
        ];
        const layout = {
            title: `<b> StdCurve      a= </b>${PLparameters[0]}<b>, b= </b>${PLparameters[1]} <b>, c= </b>${PLparameters[2]} <b>, d= </b>${PLparameters[3]}`,
            xaxis: {
                title: `<b>GM-CSF (${dataObj.conc_unit})</b>`,
                type: 'log',

            },
            yaxis: {
                title: `<b>RLU:Lum</b>`,

            },
        };
        const plotId = 'plotlyDiv_' + dataObj.plate;
        Plotly.newPlot(plotId, graphdata, layout);


    }

    addComment(dataObj, Index) {
        this.removedsampleid[Index].comment = dataObj.comment?.trim();
        this.updateSubmitEnabled();
    }


    isSubmitEnabled(): boolean {
        let hasCheckedCheckbox = false;

        for (let dataObj of this.removedsampleid) {

            for (let flag of dataObj.flag) {
                if (flag) {
                    hasCheckedCheckbox = true;
                    break;
                }
            }
            if (hasCheckedCheckbox) {
                break;
            }

        }
        if (!hasCheckedCheckbox) {
            return true;
        }

        for (let dataObj of this.removedsampleid) {

            if (dataObj.comment?.trim() === '' && dataObj.flag.some((flag) => flag)) {
                return false;
            }
        }
        return true;
    }

    updateSubmitEnabled() {
        this.submitEnabled = this.isSubmitEnabled();
    }

    submitGraphDetails() {
        this.submitResultLoader = true;
        this.submitEnabled= false;
        this.searchResult.analysisData.analysisStatus = 'UpdateAnalysis';

        this.http.post(this.AnalysisDataURL, this.searchResult.analysisData).subscribe(
            res => {
                console.log(res);
                window.location.reload();
            },

            error => {
                console.log(error)
            }
        )

    }



    populateResultData(data: SearchResult) {
        this.searchResult = data;
         this.getAnalysisDataError='Unable to process analysis. Please contact Support Team.'
        this.tableGraphData = this.searchResult.frontend_graph_data;
   
        let output_file = this.getSingedURL(this.searchResult.output_result_path);
        this.searchResult.output_result_path = output_file;
        setTimeout(() => {
            this.tableGraphData.forEach((dataObj, index) => {
                this.GraphDisplay(dataObj, index);

                if (this.tableGraphData.length > this.removedsampleid.length) {

                    this.removedsampleid.push({ plate: dataObj.plate, sampleName: [], comment: dataObj?.comment, flag: dataObj.flag })
                }
            });

        }, 0);

        const status = this.searchResult.analysisStatus;

        if (status === 'Error') {
            console.log('Error occured');
        }
    }


    getDisplayString(key) {
        return this.dropDownService.nameDescMap.get(key);
    }

    getSingedURL(path: string) {
        if (path.indexOf('https') !== -1) {
            return path;
        } else if (/\S/.test(path)) {
            return this.downloadService.getUrl(path);
        } else {
            return ' ';
        }

    }


    ngOnDestroy() {
        // Unsubscribe from the observable to avoid memory leaks
        if (this.singleSearchData) {
            this.singleSearchData.unsubscribe();
        }
        if (this.currentLoginUser) {
            this.currentLoginUser.unsubscribe();
        }
    }

}
