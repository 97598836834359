export const environment = {
    production: false,
    serverUrl: 'https://7czwxdxwgj.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'biomarker-dev-upload',
    userPool: {
      UserPoolId: 'us-east-2_BYsAkAoqa',
      ClientId: '6itvogn7r763vperpnge415vu3',
      region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:3caea33e-5a9a-45d0-9898-89abd9ecb5da',
    buckets: {
      'us-east-2': 'biomarker-dev-upload'
    },
    modules: {
      key: 'analysisType',
      values: [
       
        {
          'name': 'BMR',
          'desc': 'Biomarker Report'
        }
         
      ]
  },
    defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:3caea33e-5a9a-45d0-9898-89abd9ecb5da', // REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_BYsAkAoqa', // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '6itvogn7r763vperpnge415vu3', // OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'biomarker-dev-upload', // REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', // OPTIONAL -  Amazon service region
      }
    }
  };
  const awsconfig = {
    'aws_project_region': 'us-east-2',
    'aws_cognito_region': 'us-east-2',
    'aws_user_pools_id': 'us-east-2_BYsAkAoqa',
    'aws_user_pools_web_client_id': '6itvogn7r763vperpnge415vu3',
    'aws_cognito_mfa_configuration': 'OPTIONAL',
  
  };
  export default awsconfig;