import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../layouts/shared-service';
import { AuthService, DashboardService, DBService } from '../../service';
import { Router } from '@angular/router';
import { AnalysisSummary, DashboardData, Summary } from '../../model/analysis-details.model';
import { User } from '../../model/types';
import { RTDashBoard } from '../../model/dashboard.model';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';
import { AuthNewService } from '../../service/auth-new.service';
import { delay } from 'rxjs/operators';
import { StorageService } from '../../service/storage.service';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit {
  pageTitle = 'Dashboard';
  signedInUser: any;
  loadPage = false;
  temp: any;

  title = 'Loading';

  public dashboard_data: RTDashBoard[] = [];
  public analysisSummary: AnalysisSummary[];
  public summary: Summary;
  public dateArray: [];
  public countArray: [];
  DashboardUrl:string = environment.serverUrl + '/get_dash_url';
  dashboarddata
  receiveddashboardurl:SafeResourceUrl;

  constructor(private _sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private dbService: DBService,
    private dashboardService: DashboardService,
    private newAuthService: AuthNewService,
    private loader: AppLoaderService,
    private storageService:StorageService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    // if(this.loadPage == false){
    //   this.openDialog()
    // }
    this.temp = JSON.parse(this.storageService.get('xyz'))
    this._sharedService.emitChange(this.pageTitle);

    // this.authService.getCurrentUser((err, user: User) => {
    //   this.signedInUser = user;
    //   if (!this.signedInUser || !this.signedInUser.signedIn) {
    //     this.router.navigate(['/extra-layout/signin']);
    //     return;
    //   } else {
    //     this.dbService.getSummary(this.signedInUser.username);
    //     if (this.temp == null) {
    //       this.dashboardService.getData(this.signedInUser.username);
    //     } else {
    //       this.dashboard_data = this.temp;
    //       this.loadPage = true;
    //     }

    //   }
    // });

  }

  ngOnInit() {



    this.newAuthService.getCurrentUser();
    this.newAuthService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {
      this.signedInUser = user;
      if (!this.signedInUser) {
          this.router.navigate(['/extra-layout/signin']);
          return;
      } else {
        // this.dbService.getSummary(this.signedInUser.username);
        // if (this.temp == null) {
        //   this.dashboardService.getData(this.signedInUser.username);
        // } else {
        //   this.dashboard_data = this.temp;
        //   this.loadPage = true;
        // }
        let params = new HttpParams();
        params = params.append('mode', "getUrl");
        this.http.get(this.DashboardUrl, { params: params }).subscribe(
            (data) => {
                this.dashboarddata=data;
                console.log(this.dashboarddata)
                 this.receiveddashboardurl=this.sanitizer.bypassSecurityTrustResourceUrl(this.dashboarddata.EmbedUrl);
                this.loadPage = true;
            },
            (error) => {
              console.log(error)
            }
        );


      }
    });






    // this.dashboardService.dash_board.subscribe((data: RTDashBoard[]) => {
    //   this.dashboard_data = data;
    //   // console.log(this.dashboard_data)
    //   this.loadPage = true;
    //   if (this.loadPage === true) {
    //     this.closeDialog();
    //   }
    // });
  }

  openDialog() {
    this.loader.open(this.title);
    // setTimeout(() => {
    //   this.loader.close();
    // }, this.loadingTime)
    // if(this.loadPage==true){
    //   this.loader.close();
    // }
  }

  closeDialog() {
    this.loader.close();
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }


  public sortByAnalysisDate(): void {
    this.analysisSummary.sort((a: AnalysisSummary, b: AnalysisSummary) => {

      // return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
      return this.getTime(new Date(b.date)) - this.getTime(new Date(a.date));
    });
  }


}
