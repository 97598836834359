// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.file-row.odd {
  background: #cce5ff;
}

div.file-row {
  padding: 10px 0;
}
div.file-row button {
  margin-left: 5px;
}

.progress {
  background: none;
  border: 1px #ccc solid;
}

.percentTag {
  text-align: right !important;
  color: black;
  align-items: center;
}

.fa-remove {
  display: block;
  float: right;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZpbGUtdXBsb2FkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsbUJBQUE7QUFDRjs7QUFFQTtFQUNFLGVBQUE7QUFDRjtBQUFFO0VBQ0UsZ0JBQUE7QUFFSjs7QUFFQTtFQUNFLGdCQUFBO0VBQ0Esc0JBQUE7QUFDRjs7QUFDQTtFQUNFLDRCQUFBO0VBQ0EsWUFBQTtFQUNBLG1CQUFBO0FBRUY7O0FBQ0E7RUFDQSxjQUFBO0VBQ0UsWUFBQTtBQUVGIiwiZmlsZSI6ImZpbGUtdXBsb2FkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiZGl2LmZpbGUtcm93Lm9kZHtcclxuICBiYWNrZ3JvdW5kOiAjY2NlNWZmO1xyXG59XHJcblxyXG5kaXYuZmlsZS1yb3cge1xyXG4gIHBhZGRpbmc6IDEwcHggMDtcclxuICBidXR0b24ge1xyXG4gICAgbWFyZ2luLWxlZnQ6IDVweDtcclxuICB9XHJcbn1cclxuXHJcbi5wcm9ncmVzcyB7XHJcbiAgYmFja2dyb3VuZDogbm9uZTtcclxuICBib3JkZXI6IDFweCAjY2NjIHNvbGlkO1xyXG59XHJcbi5wZXJjZW50VGFne1xyXG4gIHRleHQtYWxpZ246IHJpZ2h0ICFpbXBvcnRhbnQ7XHJcbiAgY29sb3I6IGJsYWNrO1xyXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XHJcbn1cclxuXHJcbi5mYS1yZW1vdmV7XHJcbmRpc3BsYXk6IGJsb2NrO1xyXG4gIGZsb2F0OiByaWdodDtcclxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/doc-upload/file-upload/file-upload.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE,gBAAA;EACA,sBAAA;AACF;;AACA;EACE,4BAAA;EACA,YAAA;EACA,mBAAA;AAEF;;AACA;EACA,cAAA;EACE,YAAA;AAEF;AACA,48BAA48B","sourcesContent":["div.file-row.odd{\r\n  background: #cce5ff;\r\n}\r\n\r\ndiv.file-row {\r\n  padding: 10px 0;\r\n  button {\r\n    margin-left: 5px;\r\n  }\r\n}\r\n\r\n.progress {\r\n  background: none;\r\n  border: 1px #ccc solid;\r\n}\r\n.percentTag{\r\n  text-align: right !important;\r\n  color: black;\r\n  align-items: center;\r\n}\r\n\r\n.fa-remove{\r\ndisplay: block;\r\n  float: right;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
