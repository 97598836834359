import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../../layouts/shared-service';
import { AnalysisDetails, AnalysisDetailsqPCR } from '../../../model/analysis-details.model';
import { DropDownService } from '../../../service/drop-down.service';
import { TemplateService } from '../../../service/template.service';
import { UploadService } from '../../../service/upload.service';
import * as XLSX from 'xlsx';
import { StorageService } from '../../../service/storage.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Component({
  selector: 'app-qpcr-report',
  templateUrl: './qpcr-report.component.html',
  styleUrls: ['./qpcr-report.component.scss']
})
export class QpcrReportComponent implements OnChanges {

  @Input() analysistype: string;
  @Input() editvalues: any;
  @Output() formvalue = new EventEmitter<any>();
  pageTitle = 'Analyze Report/Data';

  analysisFormGroup: FormGroup;
  maxDate;
  // lts80_dialog: FormControl = new FormControl();
  analysisDetails: AnalysisDetails;
  subtype: AnalysisDetails;
  tableNamesList: any[];
  tableNames: any;
  showTable = false;
  countTable = false;
  post: any;
  tableParamValue = [];
  GenTablesList = [];
  ValTablesList = [];
  SumTablesList = [];
  Smparameterlist = [];
  NewSmparameterlist = [];
  Lmparameterlist = [];
  NewLmparameterlist = [];
  selectedValTables = [];
  selectedAssayTables = [];
  selectedGeneralTables = [];
  customdisable: boolean = true;
  disabledValue;
  data: any[] = [];
  tables: any[] = [];
  formGroupArray: FormArray;
  decimalNumberPattern = '^[0-9]+(\.[0-9]+)?$';
  tempdataerrorvalue: boolean = false;
  tempdataerror

  constructor(
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private uploadService: UploadService,
    private dropDownService: DropDownService,
    private templateService: TemplateService,
    private ref: ChangeDetectorRef,
    private storageService: StorageService
  ) {
    this._sharedService.emitChange(this.pageTitle);
    uploadService.disabledValue.subscribe(res => {
      this.disabledValue = res;
     
    })
   
  }
 
  ngOnChanges(changes: SimpleChanges) {
  
    if ('analysistype' in changes) {
      if (changes.analysistype.currentValue) {
        this.analysistype = changes.analysistype.currentValue;
      }
   
    }
    
    this.createForm();
    // this.getData();
  }



  createForm()
   {
    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [this.analysistype],
      // Intra_Precision_CV: [null],
      projectCode: [null],
      // Inter_Precision_CV: [null],
      multipleAnalytes: ['No'],
      analyteNames: this.formBuilder.array([this.addAnalyteRows()]),
      files: [null],
      file_path: [null],
      analyteName: ['qPCR'],
      // ULOQValue:['',[Validators.pattern(this.decimalNumberPattern)]],
      // LLOQValue:['',[Validators.pattern(this.decimalNumberPattern)]],
      // thresholdLayout: ['False'],
      // tableParamValue: [null],
     
    });
    this.ref.detectChanges();


    if (this.editvalues !== undefined) {
      const   qPCRvalue: AnalysisDetailsqPCR = new AnalysisDetailsqPCR()
      qPCRvalue.analysisType = this.analysistype;
      // qPCRvalue.Intra_Precision_CV=this.editvalues.Intra_Precision_CV;
      // qPCRvalue.Inter_Precision_CV=this.editvalues.Inter_Precision_CV;
      qPCRvalue.projectCode = this.editvalues.projectCode;
      qPCRvalue.multipleAnalytes = this.editvalues.multipleAnalytes;
      qPCRvalue.analyteNames = this.editvalues.analyteNames;
      qPCRvalue.file_path = this.editvalues.file_path;
      qPCRvalue.files = this.editvalues.files;
      qPCRvalue.analyteName=this.editvalues.analyteName;
      // qPCRvalue.ULOQValue = this.editvalues.ULOQValue;
      // qPCRvalue.LLOQValue = this.editvalues.LLOQValue;
      // qPCRvalue.thresholdLayout = this.editvalues.thresholdLayout;
      // qPCRvalue.tableParamValue = this.editvalues.tableParamValue;

      for (let i = 1; i < this.editvalues.analyteNames.length; i++) {
        this.analyteNames.push(this.addAnalyteRows());
      }
      this.analysisFormGroup.setValue(qPCRvalue);

    }

  }

 
  get formControls() { return this.analysisFormGroup.controls; }

  onSubmit(post: any) {
   
    // tslint:disable-next-line: max-line-length

    this.analysisDetails = Object.assign({}, this.analysisFormGroup.value);
    this.analysisDetails.userId = this.storageService.get('username');
   
    if (this.analysisDetails.multipleAnalytes === 'Yes') {
      this.analysisDetails.multipleAnalytes = 'True'
    } else {
      this.analysisDetails.multipleAnalytes = 'False'
    }

    this.analysisDetails.analysisId = (new Date).getTime().toString();
   
      this.analysisDetails.analyteName=  this.analyteNames.value.map(res =>
      res= res.analyteName)
    this.post = post;
    // if (this.tableNames?.[0]?.parameter.parameter_value !== this.tableParamValue) {

      // if (this.analysistype == 'SMR') {
      //   if (this.tableParamValue = []) {

      //     this.analysisDetails.tableParamValue = this.Smparameterlist;
      //   }
      //   else {
      //     this.analysisDetails.tableParamValue = this.NewSmparameterlist;
      //   }

      // }
      // else {
        // if (this.tableParamValue = []) {

        //   this.analysisDetails.tableParamValue = this.Lmparameterlist;
        // }
        // else {
        //   this.analysisDetails.tableParamValue = this.Lmparameterlist;
        // }
      // }


    // }

   
    this.formvalue.emit(this.analysisDetails);


  }

  getData() {

    const analysis_type = this.analysistype;
    if (this.editvalues !== undefined) {

      const analysis_subtype = this.editvalues.analysisSubtype;

      this.templateService.getTableData().subscribe((table_names: []) => {
        try {

          this.tableNames = table_names;
          this.tableNames = Object.entries(this.tableNames).map((e) => ({ [e[0]]: e[1] })) ?? [];
          this.tableNames[0]?.parameter.forEach((res, index) => {
                    // if (res.analysis_type == 'SMR') {
                    //   this.Smparameterlist.push(this.tableNames[0].parameter[index]);
                    //   this.Smallvaluechanges(this.editvalues.tableParamValue);
        
                    // }
                    // else {
                    if(res.analysis_type=='LMR') {
                     this.Lmparameterlist.push(this.tableNames[0].parameter[index]);
                      this.Largevaluechanges(this.editvalues.tableParamValue);
                    }
        
                    // }
                  })

          
        }
        catch (error) {
          console.log(error);
        }

      },
        error => {
          console.log(error)
          this.tempdataerrorvalue = true;
          this.tempdataerror = 'Internal Servor error, Cannot get threshold values.Please refresh the page';
          
        }
      );
    }
    else {
      const analysis_subtype = this.analysisFormGroup.value?.analysisSubtype;


      this.templateService.getTableData().subscribe((table_names: any) => {
        try {
       
          this.tableNames = table_names;

          this.tableNames = Object.entries(this.tableNames).map((e) => ({ [e[0]]: e[1] }));

          this.tableNames[0]?.parameter.forEach((res, index) => {
            // if (res.analysis_type == 'SMR') {
            //   this.Smparameterlist.push(this.tableNames[0].parameter[index])

            // }
            // else {
            if(res.analysis_type =='LMR')
            {
              this.Lmparameterlist.push(this.tableNames[0].parameter[index]);
            }

            // }
          })


        }
        catch (error) {
          console.log(error);
        }
      },
        error => {
          console.log(error)
          this.tempdataerrorvalue = true;
          this.tempdataerror = 'Internal Servor error, Cannot get threshold values.Please refresh the page';
       
        });

    }
  }

  Smallvaluechange(event, index) {
    this.tableParamValue = []
    this.tableParamValue = event?.target?.value;
    this.Smparameterlist[index].parameter_value = this.tableParamValue;
    const result = this.Smparameterlist.map(({ analysis_type, parameter_label, ...data }) => ({ ...data }));
    this.NewSmparameterlist = result;


  }

  Smallvaluechanges(event) {

    for (let i = 0; i < this.Smparameterlist.length; i++) {
      this.Smparameterlist[i].parameter_value = this.editvalues?.tableParamValue[i]?.parameter_value;
    }
  }

  Largevaluechanges(event) {
    for (let i = 0; i < this.Lmparameterlist.length; i++) {
      this.Lmparameterlist[i].parameter_value = this.editvalues?.tableParamValue[i]?.parameter_value;
    }
  }
  Largevaluechange(event, index) {
    this.tableParamValue = []
    this.tableParamValue = event?.target?.value;
    this.Lmparameterlist[index].parameter_value = this.tableParamValue;
    const result = this.Lmparameterlist.map(({ analysis_type, parameter_label, ...data }) => ({ ...data }));
    this.NewLmparameterlist = result;

  }



  onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
    
      this.processData(this.data)
    };
    reader.readAsBinaryString(target.files[0]);
  }

  processData(data: any[][]) {
    this.tables = [];
    let table = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].every(cell => cell === undefined || cell === '')) {
        if (table.length > 0) {
          this.tables.push(table);
          table = [];
        }
      } else {
        const row = data[i].map(cell => ({ value: cell, editing: false }));
        table.push(row);
      }
    }
    if (table.length > 0) {
      this.tables.push(table);
      table = [];
    }
  }

  startEditing(cell): void {
    cell.editing = true;
  }

  stopEditing(cell): void {
    cell.editing = false;
  }

  saveData(): void {
    const wb = XLSX.utils.book_new();
    this.tables.forEach((table, index) => {
      const ws = XLSX.utils.aoa_to_sheet(table.map(row => row.map(cell => cell.value)));
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet' + (index + 1));
    });
    XLSX.writeFile(wb, 'exported_data.xlsx');
  }
  

  addAnalyteRows()
  {
    return this.formBuilder.group({
    analyteName :['qPCR',[Validators.pattern('^[A-Za-z\x270-9 .,-]+$')]] ,
    LLOQValue:[''],
    ULOQValue:[''],
    })
  }


  getDropDownValues(key) 
  {
    return this.dropDownService.dropDownsMap.get(key);
  }

  get analyteNames()
   {
    return this.analysisFormGroup.get('analyteNames') as FormArray;
  }

  addAnalyte()
   {
    this.analyteNames.push(this.addAnalyteRows());
   
  }

  removeAnalyte(index) 
  {
    this.analyteNames.removeAt(index);
  }

  resetAnalysis()
   {
  

  }

  multipleAnalyteValues()
   {
    const multipleChecked = this.analysisFormGroup.value.multipleAnalytes;
    const count = this.analysisFormGroup.value.analyteNames;
   

    if (multipleChecked === 'True' && count.length === 1) {
    
      // tslint:disable-next-line: no-unused-expression
      this.analysisFormGroup.invalid;
    } else {
      console.log('no error');
    }

  }



}