// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  max-height: 600px; /* Adjust the value as needed */
  overflow-y: auto;
  margin: 20px;
  padding: 20px;
}

.heading {
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbHVtbi1kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxpQkFBQSxFQUFBLCtCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxZQUFBO0VBQ0EsYUFBQTtBQUNKOztBQUVFO0VBQ0UsMEJBQUE7QUFDSiIsImZpbGUiOiJjb2x1bW4tZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1vZGFsLWNvbnRlbnQge1xyXG4gICAgbWF4LWhlaWdodDogNjAwcHg7IC8qIEFkanVzdCB0aGUgdmFsdWUgYXMgbmVlZGVkICovXHJcbiAgICBvdmVyZmxvdy15OiBhdXRvO1xyXG4gICAgbWFyZ2luOiAyMHB4O1xyXG4gICAgcGFkZGluZzogMjBweDtcclxuICB9XHJcblxyXG4gIC5oZWFkaW5ne1xyXG4gICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XHJcbiAgIH1cclxuICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/column-dialog/column-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA,EAAA,+BAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEE;EACE,0BAAA;AACJ;AACA,ooBAAooB","sourcesContent":[".modal-content {\r\n    max-height: 600px; /* Adjust the value as needed */\r\n    overflow-y: auto;\r\n    margin: 20px;\r\n    padding: 20px;\r\n  }\r\n\r\n  .heading{\r\n    text-decoration: underline;\r\n   }\r\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
