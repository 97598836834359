// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkAriadne {
  font-size: 18px;
  float: right;
  color: black;
}

.linkAriadne:hover {
  color: red;
}

.imgAriadne {
  height: 60px !important;
}

.inputSignin {
  border-top: none;
  border-left: none;
  border-right: none;
  float: left;
}

.floatLeft {
  float: left;
}

.responsive {
  width: 100px;
  max-width: 100%;
  height: auto;
}

.error {
  font-size: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZUFBQTtFQUNBLFlBQUE7RUFDQSxZQUFBO0FBQ0o7O0FBQ0E7RUFDSSxVQUFBO0FBRUo7O0FBQUE7RUFDSSx1QkFBQTtBQUdKOztBQURBO0VBQ0ksZ0JBQUE7RUFDQSxpQkFBQTtFQUNBLGtCQUFBO0VBQ0EsV0FBQTtBQUlKOztBQUZBO0VBQ0ksV0FBQTtBQUtKOztBQUhBO0VBQ0ksWUFBQTtFQUNBLGVBQUE7RUFDQSxZQUFBO0FBTUo7O0FBSkE7RUFDSSxlQUFBO0FBT0oiLCJmaWxlIjoiY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubGlua0FyaWFkbmV7XHJcbiAgICBmb250LXNpemU6IDE4cHg7XHJcbiAgICBmbG9hdDogcmlnaHQ7XHJcbiAgICBjb2xvcjogYmxhY2s7XHJcbn1cclxuLmxpbmtBcmlhZG5lOmhvdmVye1xyXG4gICAgY29sb3I6IHJlZDtcclxufVxyXG4uaW1nQXJpYWRuZXtcclxuICAgIGhlaWdodDogNjBweCAhaW1wb3J0YW50O1xyXG59XHJcbi5pbnB1dFNpZ25pbntcclxuICAgIGJvcmRlci10b3A6IG5vbmU7XHJcbiAgICBib3JkZXItbGVmdDogbm9uZTtcclxuICAgIGJvcmRlci1yaWdodDogbm9uZTtcclxuICAgIGZsb2F0OiBsZWZ0O1xyXG59XHJcbi5mbG9hdExlZnR7XHJcbiAgICBmbG9hdDogbGVmdDtcclxufVxyXG4ucmVzcG9uc2l2ZSB7XHJcbiAgICB3aWR0aDogMTAwcHg7XHJcbiAgICBtYXgtd2lkdGg6IDEwMCU7XHJcbiAgICBoZWlnaHQ6IGF1dG87XHJcbn1cclxuLmVycm9yIHtcclxuICAgIGZvbnQtc2l6ZTogMTJweDtcclxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/signin/component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,YAAA;AACJ;;AACA;EACI,UAAA;AAEJ;;AAAA;EACI,uBAAA;AAGJ;;AADA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAIJ;;AAFA;EACI,WAAA;AAKJ;;AAHA;EACI,YAAA;EACA,eAAA;EACA,YAAA;AAMJ;;AAJA;EACI,eAAA;AAOJ;AACA,wmCAAwmC","sourcesContent":[".linkAriadne{\r\n    font-size: 18px;\r\n    float: right;\r\n    color: black;\r\n}\r\n.linkAriadne:hover{\r\n    color: red;\r\n}\r\n.imgAriadne{\r\n    height: 60px !important;\r\n}\r\n.inputSignin{\r\n    border-top: none;\r\n    border-left: none;\r\n    border-right: none;\r\n    float: left;\r\n}\r\n.floatLeft{\r\n    float: left;\r\n}\r\n.responsive {\r\n    width: 100px;\r\n    max-width: 100%;\r\n    height: auto;\r\n}\r\n.error {\r\n    font-size: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
