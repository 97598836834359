import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DBService, UploadService } from '../../service';
import { ContainerEvents } from './types';
import {
    AnalysisDetails,
    Analyte,
    FileObject,
    FileObjectStatus,
    RDSAnalysisData,
    Uploads
} from '../../model/analysis-details.model';
import { AuthService } from '../../service';
import { User } from '../../model/types';
import { SharedService } from '../../layouts/shared-service';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { DialogComponent } from '../dashboard/dialog/dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { AuthNewService } from '../../service/auth-new.service';
import { StorageService } from '../../service/storage.service';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


@Component({
    selector: 'app-doc-upload',
    templateUrl: './doc-upload.component.html',
    styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit {
    disabledValue
    colorLabel = 'DarkBlue';
    uploadDisabled = false;
    disablefileupload = true;
    pageTitle = 'New Analysis';
    files: FileObject[] = [];
    signedInUser: User;
    @Input() analysisDetails: AnalysisDetails;
    analyte: Analyte;
    modelChanges: Subject<string> = new Subject<string>();
    finish = false;
    step = 0;
    disableAdd: boolean = false;
    verificationStatus = true;
    verificationMessage: string;
    submitStatus = true;
    submitMessage: string;
    sub: User;
    deleteIndex: number;
    file_path: string;
    total_files: any = [];
    upload_types_SAM = ['Sample Analysis Report', 'Method Validation Report'];
    subtype: AnalysisDetails;
    submitResultLoader: boolean = false;
    disableMatPanel: boolean = false;
    @Output() matPanel = new EventEmitter<any>();
    data: any[] = [];
    tables: any[] = [];
    showExcelData: boolean = false;
    stickyHeaders: boolean[] = [];
    selectedCells: { tableIndex: number, rowIndex: number, cellIndex: number }[] = [];
    selectedCellsByColumn: { [key: string]: any[] } = {};
    uploaded_files = 0;
    submitFlag: Subscription;
    uploadingError:string


    constructor(
        private authService: AuthService,
        private router: Router,
        private uploadService: UploadService,
        public dialog: MatDialog,
        private storageService: StorageService,
        private snackBar: MatSnackBar) {
        // this._sharedService.emitChange(this.pageTitle);

        uploadService.fileUploadEvent$.subscribe(
            fileObject => this.handleFileUploadEvent(fileObject)
        );
        uploadService.disabledValue.subscribe(res => {
            this.disabledValue = res;

        })
        this.stickyHeaders = this.tables.map(() => false);

    }

    private handleFileUploadEvent(fileObject: FileObject) {
        if (fileObject.status === FileObjectStatus.Deleted) {
            for (const upload of this.analyte.uploadObjs) {
                for (let i = 0; i < upload.files.length; i++) {
                    if (upload.files[i] === fileObject) {
                        upload.files.splice(i, 1);
                    }
                }
            }

        }
    }

    fileChangeEvent(fileInput: any, uploadType: string) {
        const validExtensions = ['xls', 'xlsx'];
        this.disableAdd = false;
this.uploadDisabled=true;
        // tslint:disable-next-line: forin
        for (const index in this.analyte.uploadObjs) {

            if (this.analyte.uploadObjs[index].uploadType === uploadType) {
                if (fileInput.target.files && fileInput.target.files.length) {
                    for (let i = 0; i < fileInput.target.files.length; i++) {
                        this.analysisDetails.files = []
                        const fileObject = new FileObject(fileInput.target.files[i], uploadType);
                        const extension = fileObject.name.split('.').pop().toLowerCase();
                        if (validExtensions.indexOf(extension) !== -1) {
                            this.readExcelData(fileInput.target.files[i])
                        }

                        this.analyte.uploadObjs[index].files.push(fileObject);
                    }
                    this.analyte.tempPath = this.analyte.uploadObjs[index].path;

                }

            }
        }
        fileInput.target.value = null;
    }

    readExcelData(file) {
        this.showExcelData = true;
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

            this.processData(this.data)
        };
        reader.readAsBinaryString(file);
    }

    processData(data: any[][]) {
        this.tables = [];
        let table = [];
        let headers = [];
    
        for (let i = 0; i < data.length; i++) {
            if (data[i].every(cell => cell === undefined || cell === '')) {
                if (table.length > 0) {
                    this.tables.push({ headers, rows: table });
                    table = [];
                    headers = [];
                }

            }
            else {
                const row = data[i].map(cell => ({ value: cell, editing: false }));
                if (headers.length === 0) {
                    headers = row.map(cell => cell.value);
                }
                table.push(row);
            }
        }
        if (table.length > 0) {
            this.tables.push({ headers, rows: table });
            table = [];
        }
     
    }

    extractData() {
        const data = this.tables.map(table =>
            table.rows.map(row =>
                row.map(cell => cell.value)
            )
        );

        this.analysisDetails.excelTableData = Object.assign({}, data);
        this.analysisDetails.excelTableData = {
            std_conc_data: data[0],
            qc_conc_data: data[1],
            plate_map: data[2]
        }

        return data;
    }

    toggleCellSelection(cellValue: any, tableIndex: number, rowIndex: number, cellIndex: number, columnName: string): void {
        
        if (!this.selectedCellsByColumn[columnName]) {
            this.selectedCellsByColumn[columnName] = [];
        }

        const cell = { tableIndex, columnName, cellValue, rowIndex, cellIndex };

        const cellKey = `${tableIndex}-${rowIndex}-${cellIndex}`;
        const selectedColumnCells = this.selectedCellsByColumn[columnName];
        const cellIndexInSelected = selectedColumnCells.findIndex(
            selectedCell => selectedCell.key === cellKey
        );

        if (cellIndexInSelected === -1) {
            if (selectedColumnCells.length >= 2) {
                this.showToast('You can only select a maximum of 2 cells per column.');
                return;
            }
            selectedColumnCells.push({ key: cellKey, value: cellValue });
        } else {

            selectedColumnCells.splice(cellIndexInSelected, 1);

            if (selectedColumnCells.length === 0) {
                delete this.selectedCellsByColumn[columnName];
            }
        }
      
        this.analysisDetails.analytes_lloq_uloq = this.selectedCellsByColumn;
    }

    showToast(message: string): void {
        this.snackBar.open(message, 'Close', {
            duration: 3000,
        });
    }

    isSelected(tableIndex: number, rowIndex: number, cellIndex: number, columnName): boolean {
        const cellKey = `${tableIndex}-${rowIndex}-${cellIndex}`;
        return this.selectedCellsByColumn[columnName]?.some(
            selectedCell => selectedCell.key === cellKey
        );

    }


    getUploadObj(): RDSAnalysisData {
        const analysisKeyObj: RDSAnalysisData = {
            analysis_id: this.analysisDetails.analysisId,
            group_id: this.storageService.get('group'),
            organization_id: this.storageService.get('organization'),
            user_id: this.storageService.get('username'),
            file_name: this.analyte.uploadObjs[0].files[0].name,
            analysis_type: this.analysisDetails.analysisType,
            project_code: this.analysisDetails.projectCode,
        };
        this.analysisDetails.group = analysisKeyObj.group_id;
        this.analysisDetails.organization = analysisKeyObj.organization_id;

        return analysisKeyObj;
    }


    // initiateAnalysis() {
    //     const analysisKeyObj = this.getUploadObj();
    //     console.log(analysisKeyObj)
    //     this.dbService.verifyAnalysis(analysisKeyObj).subscribe({
    //         next: data => {
    //             console.log('DATA on Component page',data);
    //             console.log(data);
    //             if (data.processcode === 'Error') {
    //                 this.verificationStatus = false;
    //                 this.verificationMessage = 'Error, file not uploaded: file with identical name previously uploaded. Please rename the file and upload it again to continue.'
    //                 console.log(data.processcode)
    //             }
    //         },
    //         error: err => {
    //             this.verificationStatus = false;
    //             this.verificationMessage = 'Processing Error, Please contact Administrator.';
    //             console.log(err)
    //         }
    //     });
    // }


    uploadAll(uploadType: string) {
        // this.initiateAnalysis();

        this.disableMatPanel = true;

            if (this.verificationStatus) {
                this.disablefileupload = false;
                this.uploadDisabled = false;
                this.disableAdd = true;
                this.analysisDetails.files = this.analyte.uploadObjs[0].files.map(res => res.name);

                if (this.analyte.uploadObjs[0].files) {
                    const total_files = this.analyte.uploadObjs[0].files.length;
                    for (const file of this.analyte.uploadObjs[0].files) {
                        try {
                            this.uploadService.uploadFile(file, this.analysisDetails.file_path).subscribe(
                                response => {
    
                                    this.uploaded_files++;
                                    this.uploadService.SuccessUpload();
                                    if (total_files == this.uploaded_files) {
                                        this.submitFlag = this.uploadService.disabledValue.subscribe(res => {
                                            res = false;
                                            this.disabledValue = res;
                                        }
                                        )
                                    }
    
                                },
                                (error) => {
                                    console.log( error);
                                this.uploadingError='Unable to upload(s) file. Please contact Support Team.'
                            }
                            )
                        }
                        catch (error) {
                            this.uploadService.FailedUploaded();
                            console.log(error)
                        }
                    }
    
                }
    

                // this.uploadService.setAnalysisData(this.analyte);
                // this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);
                // this.updateAnalysisDetails(uploadType);
                // this.uploadDisabled = true;
                // this.disableAdd = true;
            } else {
                this.analyte.uploadObjs[0].files = [];
            }
        this.matPanel.emit(this.disableMatPanel)
    }




    uploadSingle(uploadType: string) {
        this.uploadService.setAnalysisData(this.analyte);
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);
        this.updateAnalysisDetails(uploadType);
    }

    getUploadPath(uploadType: string) {

        const relativePath = [this.signedInUser?.username,
        this.signedInUser?.userId,
        this.analysisDetails.analysisId].join('/');

        if (uploadType === 'Template') {
            return [relativePath, uploadType].join('/');
        } else {
            return [relativePath, this.analysisDetails.analyteName[0], uploadType].join('/');
        }
    }

    updateAnalysisDetails(uploadType: string) {

        let analyteFound = false;
        let typefound = false;
        if (this.analysisDetails.analytes.length > 0) {


            let uploadObj: Uploads;

            for (const obj of this.analyte.uploadObjs) {
                if (obj.uploadType === uploadType) {
                    uploadObj = obj;

                }
            }


            for (let i = 0; i < this.analysisDetails.analytes.length; i++) {
                if (this.analysisDetails.analytes[i].analyteName === this.analyte.analyteName) {
                    analyteFound = true;
                    for (let j = 0; j < this.analysisDetails.analytes[i].uploadObjs.length; j++) {
                        if (this.analysisDetails.analytes[i].uploadObjs[j].uploadType === uploadType) {
                            typefound = true;

                            this.analysisDetails.analytes[i].uploadObjs[j].files = []
                            this.analysisDetails.analytes[i].uploadObjs[j].files
                                .push(...JSON.parse(JSON.stringify(uploadObj.files)));


                            break;
                        }
                    }
                    if (!typefound) {

                        for (let k = 0; k < this.analyte.uploadObjs.length; k++) {
                            if (this.analyte.uploadObjs[k].uploadType === uploadType) {
                                this.analysisDetails.analytes[i].uploadObjs.push(JSON.parse(JSON.stringify(this.analyte.uploadObjs[k])));
                            }
                        }
                    }
                }
            }
            if (!analyteFound) {
                this.analysisDetails.analytes.push(JSON.parse(JSON.stringify(this.analyte)));
            }
        } else {
            this.analysisDetails.analytes.push(JSON.parse(JSON.stringify(this.analyte)));
        }
    }



    clearAll() {

        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
    }

    ngOnInit() {
        // this.newAuthService.getCurrentUser();
        // this.newAuthService.getCurrentLoginUser.subscribe((user: any) => {

        this.authService.getCurrentUser((err, user: User) => {

            this.signedInUser = user;
            this.uploadService.setSignedInUser(this.signedInUser);
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.setRegion();

                // House Keeping - Initialize necessary objects
                this.analysisDetails.analytes = [];
                this.analyte = new Analyte(this.analysisDetails.analyteNames[0])

                if (this.analysisDetails.analysisType === 'SMR' || this.analysisDetails.analysisType === 'LMR' ||
                    this.analysisDetails.analysisType === 'ADA' || this.analysisDetails.analysisType === 'BMR') {
                    this.analyte.uploadObjs = [];
                    let uploadType = 'Report'
                    if (this.analysisDetails.analysisSubtype === 'SA0') {
                        uploadType = 'Sample Analysis Report'
                    } else if (this.analysisDetails.analysisSubtype === 'MV0') {
                        uploadType = 'Method Validation Report'
                    }
                    if (this.analysisDetails.analysisSubtype === 'SAM') {
                        let i = 0;
                        for (const upload_type of this.upload_types_SAM) {
                            const obj: Uploads = {
                                uploadType: upload_type,
                                index: i,
                                path: this.getUploadPath(upload_type),
                                files: []
                            };
                            this.analyte.uploadObjs.push(obj);
                            i++;

                        }
                    } else {
                        const obj: Uploads = {
                            uploadType: uploadType,
                            index: 0,
                            path: this.getUploadPath('Report'),
                            files: []
                        };
                        this.analyte.uploadObjs.push(obj);

                        this.total_files = obj.files.map(res => res.name)
                        this.file_path = obj.path;
                        this.analysisDetails.file_path = this.file_path;

                    }
                }

                this.subtype = this.analysisDetails;
            }
        });



    }

    setRegion() {
        const queryParams = this.router.routerState.snapshot.root.queryParams;

        if (queryParams && queryParams.region) {
            this.uploadService.setRegion(queryParams.region);
        }
    }

    onChangeAnalysisType(str) {
        this.modelChanges.next(str);
    }

    openDialog() {
        // console.log('type', data);
        // this.dialog.open(HelpDialogComponent, {data: {name: data}, disableClose: false, width: '82%'});
        this.router.navigate(['/default-layout/template', {
            a_type: this.subtype.analysisType, a_subtype: this.subtype.analysisSubtype
        }]);
    }


    setStep(index: number) {
        this.step = index;
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
    }

    nextStep() {
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
        this.step++;
    }

    prevStep() {
        this.step--;
        this.clearAll();
    }

    submit() {
        this.extractData();
        this.submitMessage = '';
        this.disableAdd = true;
        this.uploadDisabled=false;
        this.disabledValue = true
        this.submitResultLoader = true;
        this.getUploadObj();
        this.uploadService.submitForAnalysis(this.analysisDetails).subscribe(
            (result) => {

                setTimeout(() => {
                    if (this.analysisDetails.analysisType === 'SMR' || this.analysisDetails.analysisType === 'LMR' ||
                        this.analysisDetails.analysisType === 'ADA' || this.analysisDetails.analysisType === 'BMR') {

                        this.router.navigate(['/default-layout/molecule-analysis', this.analysisDetails.analysisId,
                            this.signedInUser.username]);


                    } else {
                        this.router.navigate(['/default-layout/large-molecule', this.analysisDetails.analysisId]);
                    }
                }, 2000)

            },
            (error) => {
console.log(error)
                this.disabledValue = false
                this.submitResultLoader = false;
                this.submitStatus = false;
                if(error?.error?.message){
                    this.submitMessage = error.error.message;  
                }
                else
                {
                
                    this.submitMessage = "Unable to process document. Please contact Support Team.";
                }
            }
        );
    }

    getdeleteIndex(uploadObjsindex: number, fileuploadObjsindex: number) {
        this.showExcelData = false;
        this.analyte.uploadObjs[uploadObjsindex].files.splice(fileuploadObjsindex, 1);
        if(this.analyte?.uploadObjs[uploadObjsindex]?.files.length ==0){
            this.uploadDisabled = false;
        }


    }

}
