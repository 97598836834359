// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center_div {
  margin-top: 10px;
  margin-left: 50px;
  width: 70%;
}

.invalid-feedback {
  color: #f44336; /* Red */
}

.linkAriadne {
  font-size: 18px;
  float: right;
  color: black;
}

.linkAriadne:hover {
  color: red;
}

.imgAriadne {
  height: 60px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpZ251cC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGdCQUFBO0VBQ0EsaUJBQUE7RUFDQSxVQUFBO0FBQ0Y7O0FBQ0E7RUFDRSxjQUFBLEVBQUEsUUFBQTtBQUVGOztBQUFBO0VBQ0UsZUFBQTtFQUNBLFlBQUE7RUFDQSxZQUFBO0FBR0Y7O0FBREE7RUFDRSxVQUFBO0FBSUY7O0FBRkE7RUFDRSx1QkFBQTtBQUtGIiwiZmlsZSI6InNpZ251cC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jZW50ZXJfZGl2e1xyXG4gIG1hcmdpbi10b3A6IDEwcHg7XHJcbiAgbWFyZ2luLWxlZnQ6IDUwcHg7XHJcbiAgd2lkdGg6NzAlIC8qIHZhbHVlIG9mIHlvdXIgY2hvaWNlIHdoaWNoIHN1aXRzIHlvdXIgYWxpZ25tZW50ICovXHJcbn1cclxuLmludmFsaWQtZmVlZGJhY2sge1xyXG4gIGNvbG9yOiAjZjQ0MzM2OyAvKiBSZWQgKi9cclxufVxyXG4ubGlua0FyaWFkbmV7XHJcbiAgZm9udC1zaXplOiAxOHB4O1xyXG4gIGZsb2F0OiByaWdodDtcclxuICBjb2xvcjogYmxhY2s7XHJcbn1cclxuLmxpbmtBcmlhZG5lOmhvdmVye1xyXG4gIGNvbG9yOiByZWQ7XHJcbn1cclxuLmltZ0FyaWFkbmV7XHJcbiAgaGVpZ2h0OiA2MHB4ICFpbXBvcnRhbnQ7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/signup/signup.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,UAAA;AACF;;AACA;EACE,cAAA,EAAA,QAAA;AAEF;;AAAA;EACE,eAAA;EACA,YAAA;EACA,YAAA;AAGF;;AADA;EACE,UAAA;AAIF;;AAFA;EACE,uBAAA;AAKF;AACA,g6BAAg6B","sourcesContent":[".center_div{\r\n  margin-top: 10px;\r\n  margin-left: 50px;\r\n  width:70% /* value of your choice which suits your alignment */\r\n}\r\n.invalid-feedback {\r\n  color: #f44336; /* Red */\r\n}\r\n.linkAriadne{\r\n  font-size: 18px;\r\n  float: right;\r\n  color: black;\r\n}\r\n.linkAriadne:hover{\r\n  color: red;\r\n}\r\n.imgAriadne{\r\n  height: 60px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
