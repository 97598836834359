// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center_div {
  margin-top: 10px;
  margin-left: 50px;
  width: 70%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZlcmlmaWNhdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGdCQUFBO0VBQ0EsaUJBQUE7RUFDQSxVQUFBO0FBQ0YiLCJmaWxlIjoidmVyaWZpY2F0aW9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNlbnRlcl9kaXZ7XHJcbiAgbWFyZ2luLXRvcDogMTBweDtcclxuICBtYXJnaW4tbGVmdDogNTBweDtcclxuICB3aWR0aDo3MCUgLyogdmFsdWUgb2YgeW91ciBjaG9pY2Ugd2hpY2ggc3VpdHMgeW91ciBhbGlnbm1lbnQgKi9cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/signup/verification/verification.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,UAAA;AACF;AACA,4dAA4d","sourcesContent":[".center_div{\r\n  margin-top: 10px;\r\n  margin-left: 50px;\r\n  width:70% /* value of your choice which suits your alignment */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
