// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .main-content {
  height: 100%;
  padding: 0;
}
:host .main-content .main-content-wrap {
  height: 100%;
  padding: 0;
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImV4dHJhLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdFO0VBQ0UsWUFBQTtFQUNBLFVBQUE7QUFGSjtBQUlJO0VBQ0UsWUFBQTtFQUNBLFVBQUE7RUFDQSxXQUFBO0FBRk4iLCJmaWxlIjoiZXh0cmEuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICcuLi8uLi8uLi9hc3NldHMvc2Fzcy92YXJpYWJsZSc7XHJcblxyXG46aG9zdCB7XHJcbiAgLm1haW4tY29udGVudCB7XHJcbiAgICBoZWlnaHQ6IDEwMCU7XHJcbiAgICBwYWRkaW5nOiAwO1xyXG5cclxuICAgIC5tYWluLWNvbnRlbnQtd3JhcCB7XHJcbiAgICAgIGhlaWdodDogMTAwJTtcclxuICAgICAgcGFkZGluZzogMDtcclxuICAgICAgd2lkdGg6IDEwMCU7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/layouts/extra/extra.component.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,UAAA;AAFJ;AAII;EACE,YAAA;EACA,UAAA;EACA,WAAA;AAFN;AACA,wnBAAwnB","sourcesContent":["@import '../../../assets/sass/variable';\r\n\r\n:host {\r\n  .main-content {\r\n    height: 100%;\r\n    padding: 0;\r\n\r\n    .main-content-wrap {\r\n      height: 100%;\r\n      padding: 0;\r\n      width: 100%;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
